import React, { useEffect, useState } from 'react'
import { Navbar } from '../../../../uiComponents/movypay/Navbar'
import { Card } from '../../../../uiComponents/movypay/Card'
import { Container, DataContainer, FormContainer } from './styles'
import { InputNumber } from '../../../../uiComponents/movypay/buscard/InputNumber'
import { InputName } from '../../../../uiComponents/movypay/buscard/InputName'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentCard, setUserCards } from '../../../../actions/recharge'
import { useHistory } from 'react-router-dom'
import { logEvent } from '../../../../firebase/firebase-config'
import { setUserRechargeCard } from '../../../../db/movypay/setUserRechargeCard'
import { getUserRechargeCards } from '../../../../db/movypay/getUserRechargeCards'
import { LoadingContainer } from '../Amount/styles'
import { CircularProgress, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useQuery from '../../../../hooks/useQuery'
import { getUrlForm } from '../../../../db/movypay/getUrlForm'

export const useStyles = makeStyles(() => ({
  continue: {
    height: 38,
    backgroundColor: '#197D8F',
    color: 'white',
    textTransform: 'none',
    width: 'calc(100% - 32px)',
    borderRadius: 14,
    '&:hover': {
      backgroundColor: '#197D8F'
    },
    margin: '16px'
  },
  disabled: {
    height: 38,
    backgroundColor: '#F2F2F2',
    '&:hover': {
      backgroundColor: '#F2F2F2'
    },
    color: '#BCBEC0',
    textTransform: 'none',
    width: 'calc(100% - 32px)',
    borderRadius: 14,
    margin: '16px'
  }
}))

export const CardInformation = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const query = useQuery()

  const card = useSelector(state => state?.recharge?.bus_card)
  const company = useSelector(state => state?.recharge?.company)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const cards = useSelector(state => state?.recharge?.user_cards)
  const uid = useSelector(state => state?.auth?.uid)
  const loading = useSelector(state => state?.services?.loading)

  const [isByPassPayment, setIsByPassPayment] = useState(false)
  const [redirecting, setRedirecting] = useState(false)
  const [redirectingError, setRedirectingError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (cityConfig?.card_types?.length > 0) {
        console.log(cityConfig?.card_types[0])
        if (cityConfig?.card_types[0]?.name.toLowerCase() === 'sube') {
          setIsByPassPayment(true)
          setRedirecting(true)

          const params = {
            recharge_service_id: company?.id,
            amount: null,
            session_id: uid,
            user_recharge_card_id: null,
            city_id: cityConfig?.city_id
          }

          try {
            const response = await getUrlForm(params)
            console.log(response)
            if (response?.UrlFormulario && response.Error === null) {
              window.open(response?.UrlFormulario, '_self')
            } else if (response?.UrlFormulario === null) {
              console.log('Error', response?.Error)
              setRedirectingError(true)
            }
          } catch (e) {
            console.log(e)
            setRedirectingError(true)
          } finally {
            setRedirecting(false)
          }
        }
      }
    }
    fetchData()
  }, [cityConfig])

  const [error, setError] = useState({
    name: false,
    number: false
  })

  const handleContinue = async () => {
    const currentError = {
      name: cards?.some((current) => current?.name === card?.name),
      number: ((card?.number?.length < company?.minimum_digits || card?.number?.length > company?.maximum_digits)) || cards?.some((current) => current?.card_ref_id?.toString() === card?.number?.toString())
    }

    setError(currentError)

    if (!currentError.name && !currentError?.number) {
      const currentCard = {
        name: card?.name, // String
        card_ref_id: Number(card?.number), // Int
        balance: 300, // Int
        recharge_service_id: company?.id // Int
      }

      await setUserRechargeCard(currentCard)

      const currentUserCards = await getUserRechargeCards(company?.id)

      const currentUserCard = currentUserCards?.find(userCard => userCard.name === card?.name)

      dispatch(setUserCards(cards?.length >= 1 ? [...cards, currentUserCard] : [currentCard]))

      dispatch(setCurrentCard(currentUserCard))

      logEvent('card_recharge_card_add', { // TODO: review event
        bus_card_number: currentUserCard?.card_ref_id,
        bus_card_name: currentUserCard?.name,
        bus_card_id: currentUserCard?.id,
        bus_card_company_name: currentUserCard?.recharge_services?.name,
        user_id: uid,
        city_id: cityConfig?.city_id
      })

      if (company?.id && currentUserCard?.id) {
        history.push(`/card-recharge/amount-selection?recharge_service_id=${company?.id}&card_id=${currentUserCard?.id}&custom_token=${query.get('custom_token') || null}`)
      }
    }
  }

  return (
    <>
      {loading &&
        <LoadingContainer>
          <CircularProgress color='inherit' />
        </LoadingContainer>}
      {redirecting &&
        <LoadingContainer>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 30,
            flexDirection: 'column',
            justifyContent: 'center',
            color: '#000000'
          }}
          >
            <div>
              <CircularProgress style={{ marginRight: 16, color: '#197d8f', width: 30, height: 30 }} />
            </div>
            <p>Redirigiendo a nuestro socio de pagos...</p>
          </div>
        </LoadingContainer>}
      {redirectingError &&
        <LoadingContainer>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 30,
            flexDirection: 'column',
            justifyContent: 'center',
            color: '#000000'
          }}
          >
            <Typography variant='h6'>Error al redirigir a nuestro socio de pagos</Typography>
            <Typography variant='body1'>Por favor, intente nuevamente</Typography>
            <a
              style={{
                color: '#197d8f',
                textDecoration: 'none',
                marginTop: 16,
                cursor: 'pointer',
                display: 'block',
                backgroundColor: '#F2F2F2',
                padding: 8,
                borderRadius: 8,
                width: 'fit-content',
                textAlign: 'center'
              }}
              href='/'
            >Volver</a>
          </div>
        </LoadingContainer>}
      {!loading && !redirecting && !redirectingError && !isByPassPayment &&
        <Container>
          <div>
            <Navbar label='Ingresar datos' />
            <Card />
          </div>
          <DataContainer>
            <FormContainer>
              <form autoComplete='off'>
                <InputNumber error={error} />
                <InputName error={error} />
              </form>
            </FormContainer>
            <Button
              className={(card?.name?.length === 0 || card?.number?.length === 0) ? classes.disabled : classes.continue}
              onClick={handleContinue}
              disabled={(card?.name?.length === 0 || card?.number?.length === 0)}
            >Continuar</Button>
          </DataContainer>
        </Container>}
    </>
  )
}
